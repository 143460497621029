import type { TransferItem } from 'antd/lib/transfer';
import _get from 'lodash/get';

type SelectOption = { label: string; value: string | number; disabled?: boolean };
type SelectOptions = SelectOption[];

export const toSelectData = ({
  data = [],
  label,
  value,
  disabledCheck,
}: {
  data: any[];
  label: string;
  value: string;
  disabledCheck?: (_data: any) => boolean;
}): SelectOptions => {
  const result = [] as SelectOptions;
  if (!data || Array.isArray(data) === false || data.length === 0) return result;
  data?.forEach((d) => {
    if (_get(d, `${label}`) && _get(d, `${value}`)) {
      const response: SelectOption = {
        label: _get(d, `${label}`),
        value: _get(d, `${value}`),
      };
      if (typeof disabledCheck === 'function') {
        response.disabled = disabledCheck(d);
      }
      result.push(response);
    }
  });
  return result;
};

export const toTransferData = ({
  data = [],
  title,
  custom_title,
  key,
}: {
  data: any[];
  title: string;
  custom_title?: (_data: any) => string;
  key: string;
}): TransferItem[] => {
  const result = [] as TransferItem[];
  if (!data || Array.isArray(data) === false || data.length === 0) return result;
  data?.forEach((d) => {
    if ((_get(d, `${title}`) || typeof custom_title == 'function') && _get(d, `${key}`)) {
      let finalTitle = _get(d, `${title}`)
      if (typeof custom_title === 'function') {
        finalTitle = custom_title(d);
      }
      result.push({
        title: finalTitle,
        key: _get(d, `${key}`),
      });
    }
  });
  return result;
};

import type { Assignee, Contact } from './contact';
import type { Tag } from './tag';

export interface Channel {
  id: number;
  uuid: string;
  identity_key: string;
  status: ChannelStatus;
  whatsapp: WhatsApp;
  contact_number: string;
  is_instantiated: boolean;
  meta: ChannelMeta;
  type: 'waba' | 'whatsapp';
  info?: {
    app_id: string;
    phone_id: string;
    token: string;
    waba_id: string;
  };
  storage_size?: null | {
    files: number;
    size: number;
  };
  statistic: {
    total_contacts: number;
  };
}

export enum ChatUpdateType {
  READ_STATUS_UPDATE = 'read-status-update',
  PIN_UPDATE = 'pin-update',
  ARCHIVE_UPDATE = 'archive-update',
  TAB_ASSIGNMENT = 'tab-assignment',
  CHAT_CLOSED = 'chat-closed',
  CHAT_REOPENED = 'chat-reopened',
  TAG_UPDATE = 'tag-update',
  ASSIGNMENT_UPDATE = 'assignment-update',
  COLLABORATOR_UPDATE = 'collaborator-update',
  TAB_UPDATE = 'tab-update',
  LAST_MESSAGE_UPDATE = 'last-message-update',
}

export interface ChatUpdate {
  room: string;
  status: boolean;
  meta: any;
}

export interface MessageUpdate {
  id: string;
  room: string;
  randomId: string;
  status: MessageStatus;
  error?: Message['error'];
  text?: string;
}

export type MessageContentType =
  | 'reply'
  | 'message'
  | 'note'
  | 'image'
  | 'document'
  | 'forward'
  | 'audio'
  | 'video'
  | 'flow';
// status flow: pending -> instantiated -> autenticated -> ready -> disconnected
export type ChannelStatus = 'pending' | 'instantiated' | 'authenticated' | 'ready' | 'disconnected';

export interface ChannelMeta {
  instantiated: Date;
  created: Date;
  updated: Date;
}

export interface WhatsApp {
  name: string;
  version: string;
  platform: string;
}

export interface Chatroom {
  assignee: Assignee | null;
  id: string;
  display_name: string;
  name: string;
  contact_id: number | undefined; // undefined when create new chat room
  total_unread: number;
  is_archived: boolean;
  is_group: boolean;
  is_pinned: boolean;
  is_muted: boolean;
  mute_expiration: number | null;
  last_message: {
    text: string;
    timestamp: string;
  };
  tags: Tag[];
  assignee: Assignee | null;
  group_participants: {
    wa_contact_id: string;
    name: string;
    display_name: string;
    contact_number: string;
  }[];
}

export interface QrCode {
  url: string;
  timestamp: Date;
}

export type MessageType =
  | 'text'
  | 'absent'
  | 'deleted'
  | 'reaction'
  | 'sticker'
  | 'video'
  | 'audio'
  | 'image'
  | 'document'
  | 'location'
  | 'contact'
  | 'multiContact'
  | 'command'
  | 'button'
  | 'buttonResponse'
  | 'template'
  | 'templateReply'
  | 'note'
  | 'initiation'
  | 'forward'
  | 'product'
  | 'system'
  | 'unsupported';

export interface Message {
  id: string;
  randomId: string;
  chatId: string;
  conversation_id?: string | null;
  device: 'ios' | 'android';
  error?:
    | string
    | {
        code: number;
        title: string;
        message: string;
        error_data: {
          details: string;
        };
      };
  groupParticipants: { wa_contact_id: string; name: string }[];
  isGroup: boolean;
  isAbsent: boolean;
  isForwarded: boolean;
  isEdited: boolean;
  from: {
    id: string;
    name: string;
  };
  type: MessageType;
  text: string;
  fromMe: boolean;
  quoted: {
    messageId: string;
    from: string;
    fromMe?: boolean;
    message: {
      type: MessageType;
      text: string;
      metadata: MessageMetadata | null;
    };
  };
  ad?: null | {
    title: string;
    description: string;
    url: string;
    thumbnailUrl: string;
  };
  pricing_model: 'CBP';
  pricing_type: 'user_initiated' | 'business_initiated';
  relatedTo: string | null;
  sentBy: string | null;
  status: MessageStatus | null;
  metadata: MessageMetadata | null;
  timestamp: string;
  sortKey: string;
}

export enum MessageStatus {
  PENDING = 'pending',
  SERVER_ACK = 'server_ack',
  DELIVERY_ACK = 'delivery_ack',
  READ = 'read',
  DELETE = 'delete',
  FAILED = 'failed',
  DELETE_NOTE = 'delete-note',
  EDIT_NOTE = 'edit-note',
  EDITED = 'edited',
}

export interface MessageMetadata {
  filename?: string;
  mime?: string;
  mimetype?: string;
  url?: string;
  thumbnail?: null | string;
  lat?: number;
  lng?: number;
  name?: string;
  address?: string;
  title?: string;
  description?: string;
  data: {
    addr: any[];
    bday: string | null;
    email: string[];
    fullname: string;
    note: string;
    phone: {
      value: string;
      default: boolean;
    }[];
  }[];
  vcard?: string;
  text?: string;
  contacts?: PhoneContact[];
  footer_text?: string;
  buttons?: MessageButton[];
  id?: string;
  footer?: {
    type: 'text';
    content: string;
  };
  header?: {
    type: 'text' | 'image' | 'video' | 'document';
    content: string;
  };
  image?: {
    mimetype: string;
    url: string;
    thumbnail: string;
  };
  price?: {
    amount: number;
    currency: string;
  };
}

export interface MessageButton {
  button_id?: string;
  button_text?: ButtonText;
  type?: string;
  urlButton?: URLButton;
  index?: number;
  callButton?: CallButton;
  quickReplyButton?: QuickReplyButton;
}

export interface ButtonText {
  display_text: string;
}

export interface CallButton {
  displayText: string;
  phoneNumber: string;
}

export interface QuickReplyButton {
  display_text: string;
  id: string;
}

export interface URLButton {
  display_text: string;
  url: string;
}

export interface PhoneContact {
  name: string;
  vcard: string;
  data: any[];
}

export interface Notes {
  id: number;
  type: string;
  from: {
    id: number;
    name: string;
  };
  text: string;
  metadata: null;
  quoted: null;
  timestamp: Date;
}

export interface ScheduleMessage {
  contact: Contact;
  id: number;
  type: MessageContentType;
  text: string | null;
  flow?: {
    name: string;
    id: number;
  };
  sent_by: {
    id: number;
    name: string;
  };
  image: null | {
    url: string;
    mime: string;
  };
  audio: null | {
    url: string;
    mime: string;
    seconds: number;
  };
  meta: {
    scheduled: string;
    created: string;
    updated: string;
  };
}
